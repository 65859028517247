<template>
  <!--
    Used to edit a customer
  -->
  <div class="customerEdit">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('customer') }}
        </h4>
        <hr class="m-0 mb-3">
        <label>Name</label>
        <input
          v-model="customerDto.name"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>Abbrevation</label>
        <input
          v-model="customerDto.abbrevation"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('Abbrevation') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('address') }}</label>
        <input
          v-model="customerDto.address"
          v-focus
          class="form-control"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('city') }}</label>
        <input
          v-model="customerDto.city"
          v-focus
          class="form-control"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('zipCode') }}</label>
        <input
          v-model="customerDto.zipCode"
          v-focus
          class="form-control"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('installationList.country') }}</label>
        <input
          v-model="customerDto.country"
          v-focus
          class="form-control"
          type="text"
        >
      </div>
      <button 
        class="btn btn-primary float-right" 
        :disabled="!customerDto.name || !customerDto.abbrevation"
        @click.prevent="editCustomer()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('save') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "CustomersEdit",
  mixins: [
    errorMixin
  ],
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      customerDto: {}
    }
  },
  created () {
    this.customerDto = JSON.parse(JSON.stringify(this.customer))
  },
  methods: {
    async editCustomer () {
      await this.axios.put(`/Contact/Customer`, this.customerDto)
        .then(() => { 
          this.$snotify.success(this.$t('contacts.customerUpdatedSuccessfully'));       
          this.$emit("reload");
        this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>
<style scoped>
.customerAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>
