<template>
  <!--
    Used to create a customer
  -->
  <div class="customerAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('customer') }}
        </h4>
        <hr class="m-0 mb-3">
        <label>Name</label>
        <input
          v-model="customer.name"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>Abbrevation</label>
        <input
          v-model="customer.abbrevation"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('Abbrevation') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('address') }}</label>
        <input
          v-model="customer.address"
          v-focus
          class="form-control"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('city') }}</label>
        <input
          v-model="customer.city"
          v-focus
          class="form-control"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('zipCode') }}</label>
        <input
          v-model="customer.zipCode"
          v-focus
          class="form-control"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('installationList.country') }}</label>
        <input
          v-model="customer.country"
          v-focus
          class="form-control"
          type="text"
        >
      </div>
      <button 
        class="btn btn-primary float-right" 
        :disabled="!customer.name || !customer.abbrevation"
        @click.prevent="addCustomer()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('add') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "CustomerAdd",
  mixins: [
    errorMixin
  ],
  data () {
    return {
      customer: {}
    }
  },
  methods: {
    async addCustomer () {
      await this.axios.post(`/Contact/Customer`, this.customer)
        .then(() => { 
          this.$snotify.success(this.$t('contacts.customerAddedSuccessfully'));       
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>
<style scoped>
.customerAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>
