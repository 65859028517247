<template>
  <!--
    Used to manage the customers
  -->
  <div class="customersList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(customers)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(customers)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="textFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="textFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('textFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="showSidebarEdit(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>Edit</span>
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmModal(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>Remove</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <CustomersAdd
          v-if="addingElement"
          @reload="reloadCustomers"
        />
        <CustomersEdit
          v-else
          :customer="customer"
          @reload="reloadCustomers"
        />
      </Sidebar>
    </template>

    <SweetModal
      ref="confirmDelete"
      :title="$t('introOutroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p v-if="customer">
        {{ $t('sureToDelete', {'0' : customer.name}) }}
      </p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeCustomer()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>

import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import Sidebar from '@/components/Base/Sidebar.vue';
import CustomersEdit from '@/components/Contacts/CustomersEdit.vue';
import CustomersAdd from '@/components/Contacts/CustomersAdd.vue';

export default {
  name: "CustomersList",
  components: {
    SweetModal,
    Sidebar,
    CustomersEdit,
    CustomersAdd
  },
  mixins: [
    kendoGridMixin
  ],
  data () {
    return {
      loading: true,
      customers: null,
      customer: null,
      showSidebar: false,
      addingElement: false,
      kgm_sort: [
        {
          "field": "abbrevation",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'id',
          filterable: false,
          title: 'Id',
        },
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: 'Name',
          filterCell: "textFilter"
        },
        {
          field: 'abbrevation',
          filterable: true,
          filter: 'text',
          title: 'Abbrevation',
          filterCell: "textFilter"
        },
        {
          field: 'address',
          filterable: true,
          filter: 'text',
          title: this.$t('address'),
          filterCell: "textFilter"
        },
        {
          field: 'city',
          filterable: true,
          filter: 'text',
          title: this.$t('city'),
          filterCell: "textFilter"
        },
        {
          field: 'zipCode',
          filterable: true,
          filter: 'text',
          title: this.$t('zipCode'),
          filterCell: "textFilter"
        },
        {
          field: 'country',
          filterable: true,
          filter: 'text',
          title: this.$t('installationList.country'),
          filterCell: "textFilter"
        },
        {
          filterable: false,
          title: this.$t('options'),
          cell: 'optionsTemplate'
        }
      ]
    }
  },
  metaInfo () {
    return {
      title: this.$t('menu.customers')
    }
  },
  created () {
    this.getCustomers();
  },
  methods: {
    //#region Sidebar
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.showSidebar = true;
    },
    showSidebarEdit (customer) {
      this.customer = customer;
      this.addingElement = false;
      this.showSidebar = true;
    },
    //#endregion

    //#region API-calls
    reloadCustomers () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getCustomers();
    },
    openConfirmModal (customer) {
      this.customer = customer;
      this.$refs.confirmDelete.open();
    },
    removeCustomer () {
      this.axios.delete(`/Contact/Customer?id=${this.customer.id}`, )
        .then(() => {
          this.$snotify.success(this.$t('contacts.customerDeletedSuccessfully'));
          this.reloadCustomers();
        })
        .finally(() => {
          this.$refs.confirmDelete.close();
        });
    },
    getCustomers () {
      this.loading = true;
      this.axios.get('/Contact/Customers')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.customers = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //#endregion
  }
}
</script>
<style>
.customersList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>